@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
    font-family: 'LoveDay';
    src: local('LoveDay'), url('./Fonts/LoveDays-2v7Oe.ttf');
}

.card {
    font-family: 'Brush Script MT', cursive;
    transform-style: preserve-3d;
}

/* .card-translate-before {
    animation: card-translate_ 4s ease-in-out infinite;
}

@keyframes card-translate_ {
    0% {
        transform: translateY(-128px)
    }

    50% {
        transform: translateY(-138pxpx)
    }
    100% {
        transform: translateY(-128px)
    }

} */

.card::before {
    content: '';
    position: absolute;
    left: 12px;
    top: 12px;
    bottom: 0px;
    width: 90%;
    height: 90%;
    @apply border-[3px] border-[#a504f6] border-dotted;
}

.back-envelope::before {
    content: '';
    position: absolute;
    width: 237px;
    height: 237px;
    background-color: #8202d2;
    rotate: calc(45deg);
    ;
    top: -117px;
    left: 46px;
    border-radius: 30px 9px 0px 9px;
}

.back-envelope {
    animation: en-translate 4s infinite ease-in-out;
}

@keyframes en-translate {
    0% {
        transform: translateY(0px)
    }

    50% {
        transform: translateY(-10px)
    }

    100% {
        transform: translateY(0px)
    }
}

.front-envelope {
    position: absolute;
    border-right: 180px solid #8804db;
    border-top: 95px solid transparent;
    border-bottom: 102px solid transparent;
    left: -15px;
    top: -79px;
    width: 0;
    height: 0;
    z-index: 3;
    @apply rounded-lg;
    animation: en-translate 4s infinite ease-in-out;
}

.front-envelope:before {
    z-index: 2;
    @apply rounded-lg;
    position: absolute;
    content: "";
    border-left: 330px solid #ac32f8;
    border-top: 205px solid transparent;
    left: -148px;
    top: -102px;
    width: 0;
    height: 0;
}


.shadow {
    animation: shadow-translate 4s infinite ease-in-out;
}

@keyframes shadow-translate {
    0% {
        transform: translateY(186px);
        opacity: 0.5;
    }

    50% {
        transform: translateY(176px);
        scale: (1.05);
        opacity: 1;
    }

    100% {
        transform: translateY(186px);
        opacity: 0.5;
    }
}

.text-button {
    font-family: 'LoveDay', cursive, 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}


.hearts {
    position: absolute
}

.one,
.two,
.three,
.four,
.five {
    background-color: red;
    display: inline-block;
    height: 10px;
    margin: 0 10px;
    position: relative;
    transform: rotate(-45deg);
    width: 10px;
    top: 50px;
}

.one:before,
.one:after,
.two:before,
.two:after,
.three:before,
.three:after,
.four:before,
.four:after,
.five:before,
.five:after {
    content: "";
    background-color: red;
    border-radius: 50%;
    height: 10px;
    position: absolute;
    width: 10px;
}

.one:before,
.two:before,
.three:before,
.four:before,
.five:before {
    top: -5px;
    left: 0;
}

.one:after,
.two:after,
.three:after,
.four:after,
.five:after {
    left: 5px;
    top: 0;
}

.one {
    left: 10px;
    animation: heart 1s ease-out infinite;
}

.two {
    left: 30px;
    animation: heart 2s ease-out infinite;
}

.three {
    left: 50px;
    animation: heart 1.5s ease-out infinite;
}

.four {
    left: 70px;
    animation: heart 2.3s ease-out infinite;
}

.five {
    left: 90px;
    animation: heart 1.7s ease-out infinite;
}

@keyframes heart {
    0% {
        transform: translateY(0) rotate(-45deg) scale(0.3);
        opacity: 1;
    }

    100% {
        transform: translateY(-150px) rotate(-45deg) scale(1.3);
        opacity: 0.5;
    }
}