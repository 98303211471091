@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Vegan';
    src: local('Vegan') , url('./Fonts/VeganStylePersonalUse-5Y58.ttf');
}

.loading-text {
    font-family: 'Vegan';
    font-size: 2rem;
    color: #fff;
    text-align: center;
    margin-top: 2rem;
    letter-spacing: 0.2rem;
}